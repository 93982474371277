import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 28 28';
const id = 'place_outline_28';
const content = '<symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" id="place_outline_28"><g fill="none" fill-rule="evenodd"><path d="M0 0h28v28H0z" /><path d="M23 12.015C23 7.036 18.971 3 14 3s-9 4.036-9 9.015c0 3.548 2.18 7.83 6.48 12.915a3.3 3.3 0 005.04 0c4.3-5.085 6.48-9.367 6.48-12.915zm-8.16 11.777a1.3 1.3 0 01-1.833-.153C8.983 18.88 7 14.983 7 12.015 7 8.14 10.134 5 14 5s7 3.14 7 7.015c0 2.968-1.983 6.866-6.007 11.624a1.3 1.3 0 01-.153.153zM10 12a4 4 0 118.001.001A4 4 0 0110 12zm2 0a2 2 0 104.001-.001A2 2 0 0012 12z" fill="currentColor" fill-rule="nonzero" /></g></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon28PlaceOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon28PlaceOutline: FC<Icon28PlaceOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 28,
    height: !isNaN(props.height) ? +props.height : 28,
  }));
};

(Icon28PlaceOutline as any).mountIcon = mountIcon;

export default Icon28PlaceOutline;
