import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 24 24';
const id = 'storefront_outline_24';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" id="storefront_outline_24"><path fill-rule="evenodd" clip-rule="evenodd" d="M7 14a.9.9 0 01.9.9v4.2h8.2v-4.2a.9.9 0 011.8 0V20a.9.9 0 01-.9.9H7a.9.9 0 01-.9-.9v-5.1A.9.9 0 017 14z" fill="currentColor" /><path fill-rule="evenodd" clip-rule="evenodd" d="M3 20a.9.9 0 01.9-.9h16.2a.9.9 0 110 1.8H3.9A.9.9 0 013 20zm13.9-10a1.1 1.1 0 002.2 0h1.8a2.9 2.9 0 01-5.8 0h1.8z" fill="currentColor" /><path fill-rule="evenodd" clip-rule="evenodd" d="M16 9.1a.9.9 0 01.9.9 1.1 1.1 0 002.2 0 .9.9 0 111.8 0 2.9 2.9 0 01-5.8 0 .9.9 0 01.9-.9z" fill="currentColor" /><path fill-rule="evenodd" clip-rule="evenodd" d="M12 9.1a.9.9 0 01.9.9 1.1 1.1 0 002.2 0 .9.9 0 111.8 0 2.9 2.9 0 01-5.8 0 .9.9 0 01.9-.9z" fill="currentColor" /><path fill-rule="evenodd" clip-rule="evenodd" d="M8 9.1a.9.9 0 01.9.9 1.1 1.1 0 002.2 0 .9.9 0 111.8 0 2.9 2.9 0 01-5.8 0 .9.9 0 01.9-.9z" fill="currentColor" /><path fill-rule="evenodd" clip-rule="evenodd" d="M4 9.1a.9.9 0 01.9.9 1.1 1.1 0 002.2 0 .9.9 0 011.8 0 2.9 2.9 0 01-5.8 0 .9.9 0 01.9-.9z" fill="currentColor" /><path fill-rule="evenodd" clip-rule="evenodd" d="M8.703 3.1h6.594c.553 0 1.019-.001 1.453.137a2.9 2.9 0 011.033.585c.342.301.581.7.865 1.175l.056.094 1.612 2.686.027.045c.143.239.264.44.352.66.077.195.133.397.167.604.038.234.038.47.038.747V10h-1.8v-.114c0-.358-.003-.438-.014-.51a1.1 1.1 0 00-.064-.228c-.027-.068-.066-.138-.25-.445L17.16 6.017c-.37-.618-.462-.752-.566-.843a1.1 1.1 0 00-.392-.222c-.131-.042-.294-.052-1.014-.052H8.812c-.72 0-.883.01-1.014.052a1.1 1.1 0 00-.392.222c-.104.091-.196.225-.566.843L5.228 8.703c-.185.307-.223.377-.25.445a1.1 1.1 0 00-.064.228c-.011.072-.014.152-.014.51V10H3.1v-.167c0-.278 0-.513.038-.747a2.9 2.9 0 01.167-.603c.088-.22.209-.422.352-.66l.027-.046L5.296 5.09l.056-.094c.284-.474.523-.874.865-1.175a2.9 2.9 0 011.033-.585c.434-.138.9-.138 1.453-.137z" fill="currentColor" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon24StorefrontOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon24StorefrontOutline: FC<Icon24StorefrontOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 24,
    height: !isNaN(props.height) ? +props.height : 24,
  }));
};

(Icon24StorefrontOutline as any).mountIcon = mountIcon;

export default Icon24StorefrontOutline;
